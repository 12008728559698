import React, { useEffect } from 'react'
import RedirectionInProgress from './RedirectionInProgress'
import { nextLocation } from '../navigation'

/**
 * UpdateDetailsCancelled
 */
export default function UpdateDetailsCancelled() {


  useEffect(() => {
    window.location.href = nextLocation()
  }, [])


  return <RedirectionInProgress />
}
