import React, { useEffect } from 'react'
import RedirectionInProgress from './RedirectionInProgress'
import { nextLocation } from '../navigation'

/**
 * UpdateDetailsSuccess.
 */
export default function UpdatePasswordSuccess() {


  useEffect(() => {
    window.location.href = nextLocation()
  }, [])


  return <RedirectionInProgress />
}
