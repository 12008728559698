import React, { useEffect } from 'react'
import RedirectionInProgress from './RedirectionInProgress'
import { nextLocation } from '../navigation'
import { getCognitoJwt, signIn } from '../aws'
import { Hub } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { saveRedirectionInfo } from '../navigation'
/**
 * LoginSuccess.
 */
export default function RefreshToken() {
    const history = useHistory()
    Hub.listen('auth', (data) => {
        if (data.payload.event === 'signIn') {
            console.info(`auth hub has dispatched signIn`)
            window.location.href = nextLocation()
        }
    })

    useEffect(() => {
        saveRedirectionInfo()
        fetch(
            `${process.env.REACT_APP_SECURE_SHARECOVER_URI}/cb/refresh/sharecover`,
            {
                credentials: 'include',
            }
        )
            .then(() => {
                getCognitoJwt()
                    .then(signIn)
                    .catch((err) => {
                        console.error(`signIn:error ${err}`)
                        history.push('/')
                    })
            })
            .catch((err) => {
                console.error(`RefreshToken:error ${err}`)
                history.push('/')
            })
    }, [])

    return <RedirectionInProgress />
}
