/**
 * parseJwt.
 *
 * @param {} token
 */
function parseJwt(token) {
  var base64Payload = token.split('.')[1];
  var payload = Buffer.from(base64Payload, 'base64');
  return JSON.parse(payload.toString());
}


/**
 * getData.
 *
 * @param {} data
 */
function getData(data) {
  return function(token) {
    return parseJwt(token)[data]
  }
}


export default ({
  getSubject : getData('sub'),
  getEmail : getData('email'),
  getFirstName : getData('first_name'),
  getLastName : getData('last_name'),
  getMobileNumber: getData('mobile_number'),
  getExp: getData('exp')
})
