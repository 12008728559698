import React, { useEffect } from 'react'
import { signOut } from '../aws'
import RedirectionInProgress from './RedirectionInProgress'
import { Hub } from 'aws-amplify'

export default function Logout() {

  Hub.listen('auth', data => {
    if(data.payload.event === 'signOut') {
      console.info(`auth hub has dispatched signOut`)
      window.location.href = `https://${process.env.REACT_APP_DOMAIN_NAME}`
    }
  })

  useEffect(() => {
    console.info(`signin out from cognito `)
    signOut()
  })

  return <RedirectionInProgress />
}
