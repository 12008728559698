import React from 'react'
import ReactDOM from 'react-dom'
import Amplify from 'aws-amplify'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider, globalStyles } from '@sharecover-co/ui'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Global } from '@emotion/core'
import App from './components/root/App'
import * as serviceWorker from './serviceWorker'
import theme from './theme'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    cookieStorage: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN
    }
  }
})

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5
})

if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)
}

if (process.env.REACT_APP_GOOGLE_GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_GTM_ID
  }

  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Global styles={globalStyles} />
    <Router>
      <App />
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
