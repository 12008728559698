import { Auth } from 'aws-amplify'
import Cookies from 'js-cookie'
import Jwt from '../lib/jwt'

/**
 * getCognitoJwt
 *
 * Call /oidc endpoint
 *
 * @returns {Promise} Promise of object { IdentityId, Token }
 */
export async function getCognitoJwt() {
    const url = `${process.env.REACT_APP_API_SHARECOVER_URI}/oidc`
    const response = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include', // send the cookies
        mode: 'no-cors', // https://developer.mozilla.org/en-US/docs/Web/API/Request/mode
    })
    console.log(`fetch response type is: ${response.type}`)
}

function getIdentityAndToken() {
    const token = Cookies.get('x-sc-token')
    const identity_id = Cookies.get('x-sc-identity')
    if (!token) {
        setTimeout(() => {
            window.location.reload()
        }, 5000)
    }
    // Remove x-sc cookies
    Cookies.remove('x-sc-token', {
        domain: `${process.env.REACT_APP_COGNITO_DOMAIN}`,
    })
    console.debug(`x-sc-token: ${token}`)
    Cookies.remove('x-sc-identity', {
        domain: `${process.env.REACT_APP_COGNITO_DOMAIN}`,
    })
    console.debug(`x-sc-identity: ${identity_id}`)

    return { token, identity_id }
}

/**
 * signIn
 */
export async function signIn() {
    const idToken =
        Cookies.get('x-iag-user-id') || Cookies.get('x-iag-customer-id')
    const user = {
        email: Jwt.getEmail(idToken),
        iagIdentityId: Jwt.getSubject(idToken),
    }
    let now = new Date()
    let tenMinutesFromNow = now.setMinutes(now.getMinutes() + 10)
    const expires_at = tenMinutesFromNow
    const { token, identity_id } = getIdentityAndToken()

    console.debug(
        `running Auth.federatedSignIn with token ${token} and identity ${identity_id}`
    )
    await Auth.federatedSignIn(
        'developer',
        {
            token,
            identity_id,
            expires_at,
        },
        user
    )

    console.debug(`returning Auth.currentAuthenticatedUser`)
    return Auth.currentAuthenticatedUser()
}

/**
 * signOut.
 */
export async function signOut() {
    try {
        // https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js#sign-out
        await Auth.signOut({ global: true })
        console.log(`Logout successful`)
    } catch (err) {
        console.error(`Logout: could not complete action ${err}`)
    }
}
