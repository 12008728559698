import React from 'react'
import RedirectionInProgress from './RedirectionInProgress'
import { saveRedirectionInfo } from '../navigation'

const component = (uri) => () => {
    saveRedirectionInfo()
    console.debug(`going to clp: ${uri}`)
    window.location = uri

    return <RedirectionInProgress />
}

export default component
