import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ForgotPassword from '../views/ForgotPassword'
import Login from '../views/Login'
import LoginSuccess from '../views/LoginSuccess'
import RefreshToken from '../views/RefreshToken'
import Logout from '../views/Logout'
import LogoutSuccess from '../views/LogoutSuccess'
import NotFound from '../views/NotFound'
import Register from '../views/Register'
import RegisterSuccess from '../views/RegisterSuccess'
import ResetPassword from '../views/ResetPassword'
import UpdateDetails from '../views/UpdateDetails'
import UpdateDetailsSuccess from '../views/UpdateDetailsSuccess'
import UpdateDetailsCancelled from '../views/UpdateDetailsCancelled'
import UpdatePasswordSuccess from '../views/UpdatePasswordSuccess'
import withTracker from '../../utils/withTracker'

const Routes = () => (
    <Switch>
        <Route path="/" exact component={withTracker(Login)} />
        <Route
            path="/forgot-password"
            exact
            component={withTracker(ForgotPassword)}
        />
        <Route
            path="/login/success"
            exact
            component={withTracker(LoginSuccess)}
        />
        <Route path="/refresh" exact component={withTracker(RefreshToken)} />
        <Route path="/logout" exact component={withTracker(Logout)} />
        <Route
            path="/logout/success"
            exact
            component={withTracker(LogoutSuccess)}
        />
        <Route path="/register" exact component={withTracker(Register)} />
        <Route
            path="/register/success"
            exact
            component={withTracker(RegisterSuccess)}
        />
        <Route
            path="/reset-password"
            exact
            component={withTracker(ResetPassword)}
        />
        <Route
            path="/update-password/success"
            exact
            component={withTracker(UpdatePasswordSuccess)}
        />
        <Route
            path="/reset-password/success"
            exact
            component={withTracker(UpdatePasswordSuccess)}
        />
        <Route
            path="/update-details"
            exact
            component={withTracker(UpdateDetails)}
        />
        <Route
            path="/update-details/success"
            exact
            component={withTracker(UpdateDetailsSuccess)}
        />
        <Route
            path="/update-details/cancelled"
            exact
            component={withTracker(UpdateDetailsCancelled)}
        />
        <Route component={withTracker(NotFound)} />
    </Switch>
)

export default Routes
