import React, { useEffect } from 'react'
import RedirectionInProgress from './RedirectionInProgress'
import { nextLocation } from '../navigation'
import { getCognitoJwt, signIn } from '../aws'
import { Hub } from 'aws-amplify'


/**
 * LoginSuccess.
 */
export default function LoginSuccess() {

  Hub.listen('auth', data => {
    if(data.payload.event === 'signIn') {
      console.info(`auth hub has dispatched signIn`)
      window.location.href = nextLocation()
    }
  })

  useEffect(() => {
    getCognitoJwt()
      .then(signIn)
      .catch(err => console.error(`LoginSuccess: could not complete action ${err}`))
  }, [])

  return <RedirectionInProgress />
}
