export const MARKETING_REDIRECTION_IDX = 0
export const CHECKOUT_REDIRECTION_IDX = 1
export const CHECKOUT_PROFILE_REDIRECTION_IDX = 11
export const ACCOUNTS_REDIRECTION_IDX = 2

const MARKETING_URL = `https://${process.env.REACT_APP_DOMAIN_NAME}` // landing page
const CHECKOUT_URL = `https://checkout.${process.env.REACT_APP_DOMAIN_NAME}`
const CHECKOUT_PROFILE_URL = `${CHECKOUT_URL}/profile`
const ACCOUNTS_URL = `https://my.${process.env.REACT_APP_DOMAIN_NAME}`
const DEFAULT_URL = ACCOUNTS_URL

const REDIRECTIONS_H = {}
REDIRECTIONS_H[MARKETING_REDIRECTION_IDX] = MARKETING_URL
REDIRECTIONS_H[CHECKOUT_REDIRECTION_IDX] = CHECKOUT_URL
REDIRECTIONS_H[CHECKOUT_PROFILE_REDIRECTION_IDX] = CHECKOUT_PROFILE_URL
REDIRECTIONS_H[ACCOUNTS_REDIRECTION_IDX] = ACCOUNTS_URL

function saveReferrer() {
    const ref = window.document.referrer || `${ACCOUNTS_URL}${'/'}`
    console.debug(`referrer is : ${ref}`)
    ref && window.sessionStorage.setItem('referrer_was', ref)
    return window.sessionStorage.getItem('referrer_was')
}

/**
 * saveRedirectionIdx.
 * Saves an integer indicating where the user should go after authn
 */
function saveRedirectionIdx() {
    const searchParams = new URLSearchParams(window.location.search)
    console.debug(`idx is : ${searchParams}`)
    let idx = searchParams.get('redirect_idx')
    idx && window.sessionStorage.setItem('redirect_idx', idx)
    return window.sessionStorage.getItem('redirect_idx')
}

export function saveRedirectionInfo() {
    const idx = saveRedirectionIdx()
    const ref = saveReferrer()
    return idx || ref
}

/**
 * nextLocation.
 * Determine the next location
 */
export function nextLocation() {
    const idx = window.sessionStorage.getItem('redirect_idx')
    const ref = window.sessionStorage.getItem('referrer_was')
    console.debug(`idx is ${idx}`)
    console.debug(`ref was ${ref}`)
    window.sessionStorage.removeItem('redirect_idx')
    window.sessionStorage.removeItem('referrer_was')
    const url = ref || DEFAULT_URL
    console.info(`redirecting to: ${url}`)
    return url
}
